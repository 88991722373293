.loginDiv{
    width: 70%;
    margin: 0 auto;
}
#loginWord{
}
.logId{
    display:block;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 0.06rem #d1d1d1;
    width: 100%;
    margin: 10% auto;
}
.LoginLogo{
    width: 10%;
    margin: 0 2%;
}
#submitButton{
    width: 100%;
    border-radius: 10px;
    height: 2.7rem;
    background-color: #9b98e9;
}
#signUpSNS{
    margin: 10% auto;
    text-align: center;
}
#emailSignUp{
    margin: 5% auto;
    width: 70%;
    border-radius: 10px;
    height: 2.7rem;
    border: solid 3px #9b98e9;
  
}
#emailSignUp div{
    position: relative;
    top:25%;
    line-height: 100%;
}