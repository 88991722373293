.App {
  text-align: center;
}
.Tablet{
  width: 800px;
  margin: 0 auto;
}

@font-face {
  font-family: "agroB";
  src: url("./fonts/SB 어그로 B.ttf");
}
@font-face {
  font-family: "agroL";
  src: url("./fonts/SB 어그로 L.ttf");
}
@font-face {
  font-family: "agroM";
  src: url("./fonts/SB 어그로 M.ttf");
}
@font-face {
  font-family: "LeeSeoyun";
  src: url("./fonts/LeeSeoyun.ttf");
}
@font-face {
  font-family: "UhBeeZZIBA";
  src: url("./fonts/UhBeeZZIBA-Regular.ttf");
}