
.Title{
    margin: 5% 0 0 3%; 
    font-family:"agroB";
    font-size: 35px;
    width: 200px;
}
#challengeGrid{
    width: 221px;
    height:221px;
    border: 3px solid #E0E4CC;
    border-radius: 15px;
    margin:0 0 0 5%;
    position: relative;
}

#challengeGrid::after{
    background-size: 90%;
    background-position: 10%;
    background-repeat:no-repeat;
    background-image:url("../../img/cat2.jpg");
    content: '';
    width: 221px;
    height:221px;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.7;
}
.MakeGridM{
    width: 350px;
    height:221px;
    border: 3px solid #E0E4CC;
    border-radius: 15px;
    /* margin:0 3%; */
    margin:0 5%;
    text-align: center;
    padding-top: 5%;
}
.MakeGridT{
    width: 700px;
    height:221px;
    border: 3px solid #E0E4CC;
    border-radius: 15px;
    /* margin:0 3%; */
    margin:0 10%;
    text-align: center;
    /* padding-top: 5%; */
}
.RightOutLine{
    position:relative;
    line-height: 130%;
    float: right;
    /* left: 5%; */
    width: 100px;
    font-size: 30px;
}
.rankingT{
    width: 40%;
    height: 221px;
}
.rankingM{
    width: 138px;
    height: 221px;
}
.masTextM{
    display: inline-block;
    margin: 0 0 0 3%;
    font-size: 100%;
    font-family: agroB;
}
.masTextT{
    display: inline-block;
    margin: 0 0 0 5%;
    font-size: 200%;
    font-family: agroB;
}