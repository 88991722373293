.ground{
    /* background-color: black; */
    /* margin: 0 7.5vh; */
    margin:0 auto;
}
.center {
    text-align: center;
  }
.cardM{
    /* border: #F38630 1px solid; */
    border-radius: 20px;
    width: 80%;
    height: 128vw;
    /* margin: 5% auto 0 auto; */
    margin:0 10%;
    background-color: #FA6900;
    position: relative;
}
.cardT{
    /* border: #F38630 1px solid; */
    border-radius: 20px;
    width: 45%;
    height: 573px;
    /* margin: 5% auto 0 auto; */
    margin:0 auto;
    background-color: #FA6900;
    position: relative;
}
.common{
    width: 90%;
    margin: 5% 5% 0% 5%;
    /* height: 20%; */
    font-size: 250%;
    font-family: agroB;
    display: inline-block;
}
#choice{
    /* margin: 5% 0; */
    height: 48%;
}
.LikeCover{
    display: inline-block;
    line-height: middle;
    position: relative;
    top:-25%;
    margin: 0 1% 0 5%;
}
.Select{
    font-size: 170%;
    text-align: center;
    margin: 3% 5% 0 5%;
    padding: 5% 0;
    font-family: agroM;
    cursor: pointer;
}
/* .Select:hover{
    font-size: 300%;
} */
.vs{
    font-size: 130%;
    margin-top: 5%;
    text-align: center;
    font-family: agroL;
}
#timeInfo1{
    text-align: center;
    font-family: agroL;
}
#timeInfo2{
    /* margin:0 0 0 7%; */
    display: inline-block;
    font-size: 50%;
    text-align: center;
    font-family: agroL;
}
#downInfo{
    margin: 0 auto;
    text-align: center;
    font-family: agroL;
}
#view{
    display: inline;
    margin: 0 1% 0 3%;
    cursor: pointer;
}
#cardComment{
    display: inline;
    margin: 0 3%;
    cursor: pointer;
}
#singo{
    display: inline-block;
    margin:0 3%;
    cursor: pointer;
}
#save{
    display: inline-block;
    margin: 0 5% 0 1%;
    cursor: pointer;
}
#slide{
    border: solid 1px black;
    width: 40%;
}
#brandImgM{
    width: 30%;
    position: absolute;
    bottom:2%;
    left:70%;
    font-family: LeeSeoyun;
    font-size: 150%;
    color: #3935C0;
}
#brandImgT{
    width: 30%;
    position: absolute;
    bottom:2%;
    left:75%;
    font-family: LeeSeoyun;
    font-size: 150%;
    color: #3935C0;
}
.commentWindow {
margin: 5% auto 0 auto;
background-color: #E0E4CC;
border-radius: 5px;
width: 95%;
}
.board {
border-radius: 25px;
margin: 5px auto;
padding: 3% 0;
width: 90%;
}
.comment {
margin: 5px 0 0 0;
padding: 5px;
width: 100%;
text-align: left;
}
#date {
font-size: 80%;
margin:0 0 0 3%;
color:gray;
display: inline;
}
#report{
color: purple;
display: inline;
cursor: pointer;
font-size: 60%;
}
#singoComment{
    display: inline-block;
    margin:0 3%;
    cursor: pointer;
    color:rgb(252, 78, 47);
}