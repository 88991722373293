.box {
  border: solid 1px black;
  margin: 10px auto;
}
.formComment{
  text-align: center;
  margin: 0 auto 0 auto;
  padding-top: 5%;
}
.InputCommentName{
  width: 90%;
}
#InputCommentName:focus { 
  outline: none !important;
  border:2px solid green;
} 
.InputComment {
  /* resize: none; */
  width: 90%;
  height: 20%;
}
#InputComment:focus {
  outline: none !important;
  border:2px solid green;
}
.label{
  position: relative;
  text-align: left;
  left: 5%;
  margin: 1% 0;
  font-family: agroM;
}
.submit{
  border: 2px solid #69D2E7;
  border-radius: 5px;
  /* font-family: agroM; */
  font-size: 2vh;
  outline: none !important;
  color:black;
  background-color: #A7DBD8;
  width: 90%;
}
#infoDiv{
  font-family: agroL;
  margin: 0 0 0 3%;
}
.chong{
  font-size: 70%;
}
.makeCom{
  width: 85%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: #E0E4CC 1px solid;
  background: transparent;
  outline: none;
  resize: none;
  
  font-size: 250%;
  margin: 5% 15% 5% 5%;
  font-family: agroB;
}
.makeSel{
  /* border:none; */
  font-size: 170%;
  width: 90%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: #E0E4CC 1px solid;
  background: transparent;
  outline: none;
  resize: none;

  text-align: center;
  margin: 5% 0 0 5%;
  font-family: agroM;
}

#submitMake {
  border: 2px solid #69D2E7;
  border-radius: 5px;
  font-family: agroM;
  font-size: 4vh;
  outline: none !important;
  color:black;
  background-color: #A7DBD8;
  margin: 2% 0 0 5%;
  width: 90%;
}
#leftBut{
  border-radius: 5px 0 0 0;
  border:1px solid #FA6900;
  margin: 0 0 0 0;
  font-family: agroL;
  font-size: 3vh;
}
#rightBut{
  border-radius: 0 5px 0 0;
  border:1px solid #FA6900;
  font-family: agroL;
  font-size: 3vh;
}
.makeComWorry{
  width: 94%;
  font-size: 200%;
  margin: 5% 3% 0 3%;
}
.makeSelWorry{
  font-size: 170%;
}
.vsWorry{
  margin: 0 auto 0 auto;
}
