.Grid{
    border: 5px solid pink;
    /* width:600px; */
    margin: 3% auto;
    padding: 70px 0;
    float: left;
    border-radius:10px ;
    cursor: pointer;
}
.Grid:hover{
    background-color: rgb(250, 244, 245);
}
#id{
    text-decoration: none;
}