.navbar-brand{
    margin-left: 20px;
}
.btn{
    margin-right: 15px;
}
.activePage{
    font-weight: bold;
    color: black;
}

#email{
    display: block;
    font-size: 20px;
}
#login{
    margin-top: 15%;
}
.logo{
    width: 30px;
    margin: 0 5px;
}
.MakeM{
    margin: 0 0 0 25%;
    font-family: agroL;
}
.MakeT{
    margin: 0 0 0 13%;
    font-family: agroL;
}
#logo1{
    font-family: LeeSeoyun;
    /* font-family: agroM; */
    font-size: 150%;
    color: #3935C0;
    /* padding: 5px; */
    /* border: 2px solid #3935C0; */
}
.BrandT{
    text-align: center;
    margin: 0 11% 0 39%;
}