.Container{
    overflow: hidden;
    width: 100vh;
}
.sliderText{
    /* text-align: center; */
    margin: 0;
    font-family: agroM;
    font-size: 70%;
}
.textBoxM{
    display: inline-block;
    margin: 0 0 0 40%;
}
.textBoxT{
    display: inline-block;
    margin: 0 0 0 45%;
}