.GridCard{
    border: black 2px solid;
    border-radius: 15px;
    width: 138px;
    height: 221px;
    background-color: #FA6900;
}
.Gridcommon{
    width: 90%;
    margin: 5% 5% 5% 5%;
    /* height: 20%; */
    font-size: 100%;
    font-family: agroB;
}
#Gridchoice{
    /* margin: 5% 0; */
    height: 42%;
}
.Gridvs{
    font-size: 100%;
    text-align: center;
    font-family: agroL;
}
.GridSelect{
    font-size: 100%;
    text-align: center;
    margin: 5%  0;
    padding: 5% 0;
    font-family: agroM;
    cursor: pointer;
}
.brandImg1{
    width: 20%;
    /* margin:0 0 0 75%; */
    position: relative;
    top:25%;
    left:75%;
}
#GridLike{
    /* margin: 0 0 0 5%; */
    /* position: relative;
    top:10%; */
    font-family: agroL;
    display: inline;
}
#GridComment{
    display: inline;
    margin: 0 0 0 3%;
    font-family: agroM;
}
#GridTime{
    font-family: agroL;
}